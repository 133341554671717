import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import AdminSelectCommitteeWidget from '../../Generators/AdminResource/AdminSelectCommitteeWidget';
import ExportHelper from '../../TbaExportHelper';
import {formatDateStr} from '../../Utils/TimeUtil';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserSexLabel,
  getUserInformToLabel,
  getUserInformByLabel,
  getUserPunishmentTypeLabel,
  getUserInoutLeaveReasonLabel,
  getUserExperienceTbaPositionLabel,
} from '../../Utils/UserUtil';
import {getCommitteePositionLabel} from '../../Utils/SelectOptionLabelUtil';

const EXPORT_COLLECTION = 'Committee';
const EXPORT_TITLE_DEFAULT = '團體清單匯出';
const EXPORT_FIELDS = [
  {key: 'serial_number', name: '流水號'},
  {key: 'name', name: '姓名'},
  {key: 'NAME_ENG', name: '英文姓名'},
  {key: 'state', name: '狀態'},
  {key: 'substate', name: '次狀態'},
  {key: 'SEX', name: '性別'},
  {key: 'birthday', name: '生日'},
  {key: 'id_card_number', name: '身分證字號'},
  {key: 'phone', name: '手機'},
  {key: 'email', name: 'Email'},
  {key: 'EMAIL2', name: '備用Email'},
  {key: 'mail_address', name: '通訊地址'},
  {key: 'residence_address', name: '戶籍地址'},
  {key: 'company_current_name', name: '事務所名稱'},
  {key: 'company_current_address', name: '事務所地址'},
  {key: 'company_current_phone', name: '事務所電話1'},
  {key: 'company_current_fax', name: '事務所傳真1'},
  {key: 'ASSISTANT1', name: '助理1'},
  {key: 'ASSISTANT2', name: '助理2'},
  {key: 'INFORM_TO', name: '寄送地點'},
  {key: 'INFORM_BY', name: '寄送方式'},
  {key: 'seniority', name: '年資'},
  {key: 'age', name: '年齡'},
  {key: 'A_QUALIFY', name: '律師證書'},
  {key: 'QUALIFY_CTRY', name: '原資格國（地區）'},
  {key: 'inouts', name: '入會日期'},
  {key: 'withdraw_date', name: '退會日期'},
  {key: 'experiences_tba', name: '會內任職'},
  {key: 'punishments', name: '懲戒紀錄'},
  {key: 'experiences', name: '本會經歷'},
];

function transformQuery(formData) {
  const {committee} = formData;
  const query = {};

  if (!!committee) {
    query['_id'] = {$oid: committee};
  }

  return query;
}

function transformProjection(formData) {
  const projection = {};

  return projection;
}

function generateExportFields(selectAllFields, fields) {
  return selectAllFields
    ? EXPORT_FIELDS
    : EXPORT_FIELDS.filter(
        (obj) => fields.findIndex((key) => key === obj.key) > -1,
      );
}

function AdminCommitteeExport(props) {
  const rjsfProps = {
    widgets: {
      'admin-select-committee-widget': AdminSelectCommitteeWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        instance={null}
        rjsfProps={rjsfProps}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            committee: {
              type: 'string',
              title: '團體選擇',
            },
            sort: {
              type: 'string',
              title: '排序',
              default: '-created',
              enum: ['-name', '-created'],
              enumNames: ['依姓名筆畫排序', '依加入順序排序'],
            },
            selectAllFields: {
              type: 'boolean',
              title: '匯出欄位全選',
              enum: [true, false],
              enumNames: ['是', '否'],
              default: true,
            },
          },
          dependencies: {
            selectAllFields: {
              oneOf: [
                {
                  required: ['fields'],
                  properties: {
                    selectAllFields: {enum: [false]},
                    fields: {
                      type: 'array',
                      title: '匯出欄位選擇',
                      items: {
                        type: 'string',
                        enum: EXPORT_FIELDS.map((field) => field.key),
                        enumNames: EXPORT_FIELDS.map((field) => field.name),
                      },
                      uniqueItems: true,
                    },
                  },
                },
              ],
            },
          },
        }}
        uiSchema={{
          committee: {
            'ui:widget': 'admin-select-committee-widget',
          },
        }}
        onSubmit={(formData) => {
          const {selectAllFields, fields, sort} = formData;

          let query = transformQuery(formData);
          let projection = transformProjection(formData);
          let selectedFields = generateExportFields(selectAllFields, fields);

          let excelProps = {
            collection: EXPORT_COLLECTION,
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            query,
            projection,
            recordsToAoa: async (records) => {
              const record = records[0];
              try {
                const helper = new ExportHelper({
                  userIds: record.members.map((member) => member.owner),
                  seniorityUserIds: record.members.map(
                    (member) => member.owner,
                  ),
                });
                await helper.fetchAll();

                const header = `${record.group_name ? record.group_name : ''}${
                  record.NAME || ''
                } ${record.GROUP_CURRENT || ''} 共${
                  (record.members ? record.members : []).length
                }人`;

                const sortedMembers =
                  sort === '-name'
                    ? (record.members || []).sort((m1, m2) => {
                        const m1Name =
                          helper.getUserFullData(m1.owner).name || '---';
                        const m2Name =
                          helper.getUserFullData(m2.owner).name || '---';

                        if (m1Name === m2Name) {
                          return 0;
                        } else if (m1Name > m2Name) {
                          return -1;
                        }
                        return 1;
                      })
                    : record.members || [];

                return [
                  [header],
                  [...selectedFields.map((f) => f.name)],
                  ...sortedMembers.map((member, index) => {
                    const owner = member.owner;
                    const upr = helper.getUserFullData(owner);
                    const cct = helper.getCompany(upr.company_current);
                    const seniority = helper.getUserSeniority(owner);

                    const returnValues = selectedFields.map((f) => {
                      if (f.key === 'serial_number') {
                        return index + 1;
                      } else if (f.key === 'NAME_ENG') {
                        return upr.NAME_ENG;
                      } else if (f.key === 'state') {
                        return getUserStateLabel(upr.state);
                      } else if (f.key === 'substate') {
                        return getUserSubstateLabel(upr.substate);
                      } else if (f.key === 'SEX') {
                        return getUserSexLabel(upr.SEX);
                      } else if (f.key === 'birthday') {
                        return formatDateStr(new Date(upr.birthday));
                      } else if (f.key === 'mail_address') {
                        const mail_address = `${
                          upr.mail_address_zip_code || ''
                        } ${upr.mail_address_city || ''} ${
                          upr.mail_address_detail || ''
                        }`;

                        return mail_address;
                      } else if (f.key === 'residence_address') {
                        const residence_address = `${
                          upr.residence_address_zip_code || ''
                        } ${upr.residence_address_city || ''} ${
                          upr.residence_address_detail || ''
                        }`;

                        return residence_address;
                      } else if (f.key === 'company_current_name') {
                        return cct.CONAME;
                      } else if (f.key === 'company_current_address') {
                        const isDisplayUprData =
                          upr.company_zip_code ||
                          upr.company_city ||
                          upr.company_detail;

                        const upr_address = `${upr.company_zip_code || ''} ${
                          upr.company_city || ''
                        } ${upr.company_detail}`;

                        const cct_address = `${cct.address_zip_code || ''} ${
                          cct.address_city || ''
                        } ${cct.address_detail}`;

                        return isDisplayUprData ? upr_address : cct_address;
                      } else if (f.key === 'company_current_phone') {
                        return upr.company_phone || cct.COPHONE1;
                      } else if (f.key === 'company_current_fax') {
                        return upr.company_fax || cct.COFAX1;
                      } else if (f.key === 'INFORM_TO') {
                        return getUserInformToLabel(upr.INFORM_TO);
                      } else if (f.key === 'INFORM_BY') {
                        return getUserInformByLabel(upr.INFORM_BY);
                      } else if (f.key === 'seniority') {
                        return seniority.seniority || '';
                      } else if (f.key === 'age') {
                        const age = parseInt(
                          (new Date().getTime() -
                            new Date(upr.birthday).getTime()) /
                            1000 /
                            60 /
                            60 /
                            24 /
                            365,
                        );
                        return age || 0;
                      } else if (f.key === 'A_QUALIFY') {
                        const display = `${
                          upr.A_QUALIFY_DATE?.split('T')[0] || ''
                        }(${upr.A_QUALIFY_WORD || ''}${
                          upr.A_QUALIFY_NO || ''
                        })`;
                        return display;
                      } else if (f.key === 'inouts') {
                        return upr.inouts ? upr.inouts[0].EFF_DATE : '無';
                      } else if (f.key === 'withdraw_date') {
                        const inoutRecord =
                          upr.inouts &&
                          Array.isArray(upr.inouts) &&
                          upr.inouts.length > 0
                            ? upr.inouts[upr.inouts.length - 1]
                            : null;

                        const withdraw_date = inoutRecord
                          ? inoutRecord['EXPR_DATE']
                            ? inoutRecord['EXPR_DATE'] !== '9999-12-31T00:00:00'
                              ? inoutRecord['EXPR_DATE']
                              : null
                            : null
                          : null;

                        return withdraw_date || '無';
                      } else if (f.key === 'experiences_tba') {
                        return getCommitteePositionLabel(member.POSITION);
                      } else if (f.key === 'punishments') {
                        let text = ``;

                        if (
                          Array.isArray(upr.punishments) &&
                          upr.punishments.length > 0
                        ) {
                          upr.punishments.forEach(
                            (r, i) =>
                              (text += `${getUserPunishmentTypeLabel(r.KIND)} ${
                                r.MINISTRY || ''
                              }${
                                i + 1 !== upr.punishments.length ? '、' : ''
                              }`),
                          );
                        }

                        return text || '無';
                      } else if (f.key === 'experiences') {
                        let text = ``;

                        if (
                          Array.isArray(upr.experiences) &&
                          upr.experiences.length > 0
                        ) {
                          upr.experiences.forEach(
                            (r, i) =>
                              (text += `${r.EVENT} ${r.POSITION || ''}${
                                i + 1 !== upr.experiences.length ? '、' : ''
                              }`),
                          );
                        }

                        return text || '無';
                      }

                      return upr[f.key];
                    });

                    return [...returnValues];
                  }),
                ];
              } catch (err) {
                console.warn(err);
              }
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminCommitteeExport;
