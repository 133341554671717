import React from 'react';
import styled from 'styled-components';
import {InputNumber, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserSexLabel,
} from '../../Utils/UserUtil';

export async function getUserList({
  useAggregate = false,
  collection,
  query,
  sort,
  pagination,
  projection,
  pipelines,
}) {
  if (useAggregate) {
    return JStorage.aggregateDocuments(collection, pipelines);
  }

  return JStorage.fetchDocuments(
    collection,
    query,
    sort,
    pagination,
    projection,
  );
}

async function mergeCompanyDataByUsers({users}) {
  let nextUsers;

  const cIds = users.results
    .filter((r) => !!r.company_current)
    .map((r) => r.company_current);

  const companies = await JStorage.fetchAllDocuments(
    'Company',
    {
      _id: {$in: cIds.map((id) => ({$oid: id}))},
    },
    ['-created'],
    {_id: 1, CONAME: 1},
  );

  for (const user of users.results) {
    if (!!user.company_current) {
      const targetCompany = companies.find(
        (c) => c.id === user.company_current,
      );

      user.company_current_name = targetCompany.CONAME;
    } else {
      user.company_current_name = '';
    }
  }

  nextUsers = {...users};

  return nextUsers;
}

function mergeCompanyDataById({companies, list}) {
  return list.map((r) => {
    const targetCompany = companies.find((c) => c.id === r._id);
    return {
      ...r,
      name: targetCompany
        ? targetCompany.CONAME || '*未填寫事務所名稱'
        : '*未有事務所',
    };
  });
}

async function generateExcelFile(
  records,
  header,
  generateRow,
  updateWorkSheet,
) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([
      ...header,
      ...records.map(generateRow),
    ]);

    if (typeof updateWorkSheet === 'function') {
      updateWorkSheet(ws);
    }

    window.XLSX.utils.book_append_sheet(wb, ws, '訂單列表');
    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });
    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );
    await AppActions.delay(600);
    message.success('成功創建下載連結');
    return objUrl;
  } catch (ex) {
    console.warn('generateExcelFile ex', ex);
  } finally {
    AppActions.setLoading(false);
  }
}

function AdminCompanyTypeExport(props) {
  const [companies] = useOutlet('companies');

  //會員入會人數
  const [memberList1, setMemberList1] = React.useState(null);

  //會員性別年齡
  const [ageBaseDate, setAgeBaseDate] = React.useState(
    new Date().toLocaleDateString('sv'),
  );
  const [ageStart, setAgeStart] = React.useState(20);
  const [ageEnd, setAgeEnd] = React.useState(30);
  const [malesTotal, setMalesTotal] = React.useState(null);
  const [femalesTotal, setFemalesTotal] = React.useState(null);

  //會員事務所規模
  const [peopleStart, setPeopleStart] = React.useState(1);
  const [peopleEnd, setPeopleEnd] = React.useState(3);
  const [peopleTotal, setPeopleTotal] = React.useState(null);

  //事務所型態統計
  const [totalList, setTotalList] = React.useState(null);

  //企業內
  const [uprList1, setUprList1] = React.useState(null);
  const [downloadUrl1, setDownloadUrl1] = React.useState(null);

  //非營利組織
  const [uprList2, setUprList2] = React.useState(null);
  const [downloadUrl2, setDownloadUrl2] = React.useState(null);

  //前十大事務所
  const [uprList3, setUprList3] = React.useState(null);
  const [downloadUrl3, setDownloadUrl3] = React.useState(null);

  return (
    <Wrapper>
      <ItemWrapper>
        <div className="title">會員入會人數</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div className="container">
          {!memberList1 && (
            <div className="content">
              <div>總人數：--- 人</div>
              <div>一般會員： --- 人</div>
              <div>特別會員： --- 人</div>
              <div>外國法事務律師： --- 人</div>
            </div>
          )}
          {memberList1 && (
            <div className="content">
              <div>總人數：{memberList1.total} 人</div>
              <div>
                一般會員：
                {memberList1.results.filter((r) => r.substate === '4').length}人
              </div>
              <div>
                特別會員：
                {memberList1.results.filter((r) => r.substate === '5').length}人
              </div>
              <div>
                外國法事務律師：
                {memberList1.results.filter((r) => r.substate === '6').length}人
              </div>
            </div>
          )}
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                setMemberList1(
                  await getUserList({
                    collection: 'user_profile',
                    query: {
                      state: 1,
                      substate: {$in: ['4', '5', '6']},
                    },
                    sort: ['-created'],
                    pagination: {offset: 0, limit: 10000000},
                    projection: {
                      owner: 1,
                      id_card_number: 1,
                      name: 1,
                      state: 1,
                      substate: 1,
                    },
                  }),
                );
              }}>
              查詢
            </Button>
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">會員性別年齡</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div className="container">
          <div className="content">
            年齡基準日：
            <input
              type="date"
              value={ageBaseDate}
              onChange={(e) => setAgeBaseDate(e.target.value)}
            />
          </div>
          <div className="content">
            年齡區間 (起)：
            <InputNumber
              style={{width: 60, marginRight: 10}}
              value={ageStart}
              onChange={(value) => setAgeStart(value)}
            />
            年齡區間 (迄)：
            <InputNumber
              style={{width: 60}}
              value={ageEnd}
              onChange={(value) => setAgeEnd(value)}
            />
          </div>
          <div className="content">
            {!malesTotal && !femalesTotal && (
              <div>
                <div>男性人數： --- 人</div>
                <div>女性人數： --- 人</div>
              </div>
            )}

            {malesTotal && femalesTotal && (
              <div>
                <div>男性人數： {malesTotal} 人</div>
                <div>女性人數： {femalesTotal} 人</div>
              </div>
            )}
          </div>
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                try {
                  const resp = await AppActionsEx.fetchUserBaselineResults({
                    age_start: ageStart,
                    age_end: ageEnd,
                    age_base_date: ageBaseDate,
                  });

                  if (resp.length > 0) {
                    const uprs = await JStorage.fetchAllDocuments(
                      'user_profile',
                      {
                        owner: {$in: resp},
                        state: 1,
                        substate: {
                          $in: ['4', '5', '6'],
                        },
                      },
                      ['-created'],
                      {SEX: 1},
                    );

                    setMalesTotal(uprs.filter((upr) => upr.SEX === 'M').length);
                    setFemalesTotal(
                      uprs.filter((upr) => upr.SEX === 'F').length,
                    );
                    message.success('查詢完成');
                  } else {
                    setMalesTotal(0);
                    setFemalesTotal(0);

                    message.success('查詢完成');
                  }
                } catch (err) {
                  console.warn(err);
                  message.error('發生錯誤');
                }
              }}>
              查詢
            </Button>
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">會員事務所規模</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div className="container">
          <div className="content">
            人數區間 (起)：
            <InputNumber
              style={{width: 60, marginRight: 10}}
              value={peopleStart}
              onChange={(value) => setPeopleStart(value)}
            />
            人數區間 (迄)：
            <InputNumber
              style={{width: 60}}
              value={peopleEnd}
              onChange={(value) => setPeopleEnd(value)}
            />
          </div>
          <div className="content">
            {!peopleTotal && <div>事務所數量：--- 間</div>}
            {peopleTotal && <div>事務所數量：{peopleTotal.length} 間</div>}
          </div>
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                setPeopleTotal(
                  await getUserList({
                    useAggregate: true,
                    collection: 'user_profile',
                    pipelines: [
                      {
                        $match: {
                          state: 1,
                          substate: {$in: ['4', '5', '6']},
                        },
                      },
                      {
                        $project: {
                          owner: 1,
                          name: 1,
                          state: 1,
                          substate: 1,
                          company_current: 1,
                        },
                      },
                      {
                        $group: {
                          _id: '$company_current',
                          total: {
                            $sum: 1,
                          },
                        },
                      },
                      {
                        $sort: {
                          total: -1,
                        },
                      },
                      {
                        $match: {
                          _id: {$ne: null},
                        },
                      },
                      {
                        $match: {
                          total: {$gte: peopleStart, $lte: peopleEnd},
                        },
                      },
                    ],
                  }),
                );
              }}>
              查詢
            </Button>
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">會員事務所型態統計</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div className="container">
          <div className="content">
            {!totalList && (
              <div>
                <div>個人：--- 人</div>
                <div>合署：--- 人</div>
                <div>合夥：--- 人</div>
                <div>企業內：--- 人</div>
                <div>非營利組織：--- 人</div>
              </div>
            )}
            {totalList && (
              <div>
                <div>個人：{totalList['personal']} 人</div>
                <div>合署：{totalList['office']} 人</div>
                <div>合夥：{totalList['partnership']} 人</div>
                <div>企業內：{totalList['company']} 人</div>
                <div>非營利組織：{totalList['ngo']} 人</div>
              </div>
            )}
          </div>
          <div>
            <Button
              type="primary"
              onClick={async () => {
                const personal = await getUserList({
                  collection: 'user_profile',
                  query: {
                    state: 1,
                    substate: {$in: ['4', '5', '6']},
                    COMPANY_TYPE: 1,
                  },
                  sort: ['-created'],
                  pagination: {offset: 0, limit: 0},
                  projection: {owner: 1, name: 1},
                });

                const office = await getUserList({
                  collection: 'user_profile',
                  query: {
                    state: 1,
                    substate: {$in: ['4', '5', '6']},
                    COMPANY_TYPE: 2,
                  },
                  sort: ['-created'],
                  pagination: {offset: 0, limit: 0},
                  projection: {owner: 1, name: 1},
                });

                const partnership = await getUserList({
                  collection: 'user_profile',
                  query: {
                    state: 1,
                    substate: {$in: ['4', '5', '6']},
                    COMPANY_TYPE: 3,
                  },
                  sort: ['-created'],
                  pagination: {offset: 0, limit: 0},
                  projection: {owner: 1, name: 1},
                });

                const company = await getUserList({
                  collection: 'user_profile',
                  query: {
                    state: 1,
                    substate: {$in: ['4', '5', '6']},
                    COMPANY_TYPE: 4,
                  },
                  sort: ['-created'],
                  pagination: {offset: 0, limit: 0},
                  projection: {owner: 1, name: 1},
                });

                const ngo = await getUserList({
                  collection: 'user_profile',
                  query: {
                    state: 1,
                    substate: {$in: ['4', '5', '6']},
                    COMPANY_TYPE: 5,
                  },
                  sort: ['-created'],
                  pagination: {offset: 0, limit: 0},
                  projection: {owner: 1, name: 1},
                });

                setTotalList({
                  personal: personal.total,
                  office: office.total,
                  partnership: partnership.total,
                  company: company.total,
                  ngo: ngo.total,
                });
              }}>
              查詢
            </Button>
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">會員事務所型態：企業內</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div>事務所型態：企業內</div>
        <div className="container">
          <div className="content">
            {!uprList1 && (
              <div>
                <div>總人數：--- 人</div>
              </div>
            )}
            {uprList1 && (
              <div>
                <div>總人數：{uprList1.total} 人</div>
              </div>
            )}
          </div>
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                const nextUserList = await getUserList({
                  collection: 'user_profile',
                  query: {
                    state: 1,
                    substate: {$in: ['4', '5', '6']},
                    COMPANY_TYPE: 4,
                  },
                  sort: ['-created'],
                  pagination: {offset: 0, limit: 10000000},
                  projection: {
                    owner: 1,
                    id_card_number: 1,
                    name: 1,
                    state: 1,
                    substate: 1,
                    company_current: 1,
                    COMPANY_TYPE: 1,
                    SEX: 1,
                  },
                });

                const nextUserListHasCompanyData = await mergeCompanyDataByUsers(
                  {users: nextUserList},
                );

                setUprList1(nextUserListHasCompanyData);

                const exportUserData = [
                  ...nextUserListHasCompanyData.results
                    .filter((user) => !!user.company_current)
                    .sort((a, b) =>
                      a.company_current_name.localeCompare(
                        b.company_current_name,
                        'zh-Hant',
                      ),
                    ),
                  ...nextUserListHasCompanyData.results.filter(
                    (user) => user.company_current === '',
                  ),
                  ...nextUserListHasCompanyData.results.filter(
                    (user) => user.company_current === undefined,
                  ),
                ];

                const link = await generateExcelFile(
                  exportUserData,
                  [
                    ['台北律師公會會員 企業內律師'],
                    ['人數', '性別', '事務所名稱', ''],
                  ],

                  (r, i) => [
                    `${i + 1}`,
                    `${getUserSexLabel(r.SEX)}`,
                    `${
                      r.company_current
                        ? r.company_current_name || '[未填寫事務所名稱]'
                        : `[未填寫現在事務所 (型別：${typeof r.company_current} )]
                        `
                    }`,
                    `${(() => {
                      const length = exportUserData.filter(
                        (user) => user.company_current === r.company_current,
                      ).length;

                      if (i === 0) {
                        return length;
                      } else if (i > 0) {
                        const isShowLength =
                          r.company_current || r.company_current === ''
                            ? exportUserData[i - 1].company_current !==
                              r.company_current
                            : typeof exportUserData[i - 1].company_current !==
                              typeof r.company_current;

                        return isShowLength ? length : '';
                      }
                    })()}`,
                  ],
                );

                if (link) {
                  setDownloadUrl1(link);
                }
              }}>
              查詢
            </Button>
            {downloadUrl1 && (
              <Button
                type="link"
                download={'事務所型態_企業內.xlsx'}
                href={downloadUrl1}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">會員事務所型態：非營利組織</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div>事務所型態：非營利組織</div>
        <div className="container">
          <div className="content">
            {!uprList2 && (
              <div>
                <div>總人數：--- 人</div>
              </div>
            )}
            {uprList2 && (
              <div>
                <div>總人數：{uprList2.total} 人</div>
              </div>
            )}
          </div>
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                const nextUserList = await getUserList({
                  collection: 'user_profile',
                  query: {
                    state: 1,
                    substate: {$in: ['4', '5', '6']},
                    COMPANY_TYPE: 5,
                  },
                  sort: ['-created'],
                  pagination: {offset: 0, limit: 10000000},
                  projection: {
                    owner: 1,
                    id_card_number: 1,
                    name: 1,
                    state: 1,
                    substate: 1,
                    company_current: 1,
                    COMPANY_TYPE: 1,
                    SEX: 1,
                  },
                });

                const nextUserListHasCompanyData = await mergeCompanyDataByUsers(
                  {users: nextUserList},
                );

                setUprList2(nextUserListHasCompanyData);

                const exportUserData = [
                  ...nextUserListHasCompanyData.results
                    .filter((user) => !!user.company_current)
                    .sort((a, b) =>
                      a.company_current_name.localeCompare(
                        b.company_current_name,
                        'zh-Hant',
                      ),
                    ),
                  ...nextUserListHasCompanyData.results.filter(
                    (user) => user.company_current === '',
                  ),
                  ...nextUserListHasCompanyData.results.filter(
                    (user) => user.company_current === undefined,
                  ),
                ];

                const link = await generateExcelFile(
                  exportUserData,
                  [
                    ['台北律師公會會員 非營利組織律師'],
                    ['人數', '性別', '事務所名稱', ''],
                  ],

                  (r, i) => [
                    `${i + 1}`,
                    `${getUserSexLabel(r.SEX)}`,
                    `${
                      r.company_current
                        ? r.company_current_name || '[未填寫事務所名稱]'
                        : `[未填寫現在事務所 (型別：${typeof r.company_current} )]
                        `
                    }`,
                    `${(() => {
                      const length = exportUserData.filter(
                        (user) => user.company_current === r.company_current,
                      ).length;

                      if (i === 0) {
                        return length;
                      } else if (i > 0) {
                        const isShowLength =
                          r.company_current || r.company_current === ''
                            ? exportUserData[i - 1].company_current !==
                              r.company_current
                            : typeof exportUserData[i - 1].company_current !==
                              typeof r.company_current;

                        return isShowLength ? length : '';
                      }
                    })()}`,
                  ],
                );

                if (link) {
                  setDownloadUrl2(link);
                }
              }}>
              查詢
            </Button>
            {downloadUrl2 && (
              <Button
                type="link"
                download={'事務所型態_非營利組織.xlsx'}
                href={downloadUrl2}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">會員前二十大事務所</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div className="container">
          <div className="content">
            {uprList3 &&
              uprList3.map((r, i) => (
                <div>
                  {i + 1} {r.name} {r.total} 人
                </div>
              ))}
          </div>
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                const nextUserList = await getUserList({
                  useAggregate: true,
                  collection: 'user_profile',
                  pipelines: [
                    {
                      $match: {
                        state: 1,
                        substate: {$in: ['4', '5', '6']},
                      },
                    },
                    {
                      $project: {
                        owner: 1,
                        name: 1,
                        state: 1,
                        substate: 1,
                        company_current: 1,
                      },
                    },
                    {
                      $group: {
                        _id: '$company_current',
                        total: {
                          $sum: 1,
                        },
                      },
                    },
                    {
                      $sort: {
                        total: -1,
                      },
                    },
                    {
                      $match: {
                        _id: {$ne: null},
                      },
                    },
                    {
                      $limit: 20,
                    },
                  ],
                });

                const nextUserListHasCompanyData = mergeCompanyDataById({
                  companies,
                  list: nextUserList,
                });

                setUprList3(nextUserListHasCompanyData);

                const link = await generateExcelFile(
                  nextUserListHasCompanyData,
                  [['', '事務所名稱', '入會人數']],
                  (r, i) => [`${i + 1}`, `${r.name}`, `${r.total}`],
                );

                if (link) {
                  setDownloadUrl3(link);
                }
              }}>
              查詢
            </Button>
            {downloadUrl3 && (
              <Button
                type="link"
                download={'前二十大事務所.xlsx'}
                href={downloadUrl3}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-width: 400px;
  width: 100%;
  padding: 10px;
  margin: 20px;

  & > .title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  & > .container {
    margin-top: 10px;
    margin-bottom: 10px;

    & > .content {
      margin-top: 10px;
      margin-bottom: 10px;

      & > * {
        font-size: 14px;
      }
    }
  }
`;

export default AdminCompanyTypeExport;
